import React, { useState,useEffect } from 'react';
import {ReactComponent as Icon} from "../Photos/icon.svg"
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
const Forms = () => {
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Number, setNumber] = useState("");
    const [Industry, setIndustry] = useState("");
    const [NumberError, setNumberError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [CollegeName,setCollegeName]=useState("");
    const [candidate_id,setcandidate_id]=useState(0);
    const [IncompleteInfo,setIncompleteInfo]=useState(false);
    const [DisablePay,setDisablePay]=useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message,setmessage]=useState(false);    
    const handleEmail = (e) => {
        const email = e.target.value;
        setEmail(email);

        // Simple email validation regex
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const handleNumber = (e) => {
        const number = e.target.value;
        setNumber(number);

        // Check if the number is exactly 10 digits
        if (number.length !== 10 || !/^\d+$/.test(number)) {
            setNumberError("Mobile number must be exactly 10 digits.");
        } else {
            setNumberError("");
        }
    };
    // const loadScript = (src) => {
    //     return new Promise((resolve) => {
    //         const script = document.createElement('script');
    //         script.src = src;
    //         script.onload = () => resolve(true);
    //         script.onerror = () => resolve(false);
    //         document.body.appendChild(script);
    //     });
    // };
    const handlerest=()=>{
        setName("");
        setEmail("");
        setNumber("");
        setIndustry("");
        setCollegeName("");
    }
    
    
    const handleRegister = async (price, itemName) => {
        if(Name.length===0 || Email.length===0 || Industry.length===0 || Number.length===0 || CollegeName.length===0){
            setIncompleteInfo(true);
            return ;
        }
        else {
            setIncompleteInfo(false);
        }
        setDisablePay(true)
        const userdata={
            Name:Name,
            CollegeName:CollegeName,
            Email:Email,
            PhoneNumber:Number,
            Industry:Industry
        };
        try{
            const data = await axios.post("https://happyaspirantstest-2.onrender.com/api/register",userdata);
            setmessage(true);
            
            try{
                const userEmail={
                    Email:Email,
                }
                const maildata=await axios.post("https://happyaspirantstest-2.onrender.com/api/SendEmail",userEmail);
            }
            catch(err){
                alert('Our team will be contacting you soon');
            }
            setShowAlert(true);

        }
        catch(err){
            if(err.response.data==="User already exists"){
                setmessage(false);
                // console.log("err",err);
            }
            setShowAlert(true);
             
        }
        setDisablePay(false)
    };
    

    return (
        <div>
              {showAlert && (
                <Alert icon={<CheckIcon fontSize="inherit" />}
                sx={{ width: '70%', mb: 2 }}
                severity="success">
                    {message?`Registered Successfully`:"User already exists"}
                </Alert>
            )}
            <div className='flex flex-wrap sm:w-[70%] md:w-[70%] lg:w-[80%]  '>
                <div className='w-full'>
                    <h1 className='text-[#666666] text-xs font-medium leading-[18px] md:text-[18px] font-helvetica  mb-3 '>Name<span className='text-red-500 text-sm pl-[2px]' >*</span></h1>
                    <input className="flex flex-col w-full items-start gap-2.5 p-4 self-stretch rounded-xl border-[#CCC] lg:mb-5 bg-[var(--W-Background,#FFF)] border border-gray-600" type='text' placeholder='ex. Komal Kungwani' value={Name} onChange={(e) => setName(e.target.value) }></input>
                </div>
                <div className='w-full'>
                    <h1 className='text-[#666666] text-xs font-medium leading-[18px] font-helvetica md:text-[18px] mt-3 mb-3'>College Name<span className='text-red-500 text-sm pl-[2px]' >*</span></h1>
                    <input className="flex flex-col w-full items-start gap-2.5 p-4 self-stretch rounded-xl border-[#CCC] lg:mb-5 bg-[var(--W-Background,#FFF)] border border-gray-600" type='text' placeholder='ex. LNMIIT' value={CollegeName} onChange={(e)=>setCollegeName(e.target.value)}></input>
                    {/* {NumberError && <div className='flex m-2'><Icon></Icon> <p className="text-red-500 text-md ">{NumberError}</p></div>} */}
                </div>
                <div className='w-full'>
                    <h1 className='text-[#666666] text-xs font-medium leading-[18px] font-helvetica md:text-[18px] mt-3 mb-3 '>Email<span className='text-red-500 text-sm pl-[2px]' >*</span></h1>
                    <input className="flex flex-col w-full items-start gap-2.5 p-4 self-stretch rounded-xl border-[#CCC] lg:mb-5 bg-[var(--W-Background,#FFF)] border border-gray-600" type='text' placeholder='ex. contact@happyaspirants.com' value={Email} onChange={handleEmail}></input>
                    {EmailError &&<div className='flex m-2'><Icon></Icon> <p className="text-red-500 text-md ">{EmailError}</p></div>}
                    {!EmailError && <div className='flex lg:-mt-5 m-2'><p className=" text-semibold text-[10px] font-sans  font-normal leading-7 self-stretch">* Further Communications through email!</p></div>}

                </div>
                
                <div className='w-full'>
                    <h1 className='text-[#666666] text-xs font-medium leading-[18px] font-helvetica md:text-[18px] mt-3 mb-3'>Mobile Number<span className='text-red-500 text-sm pl-[2px]' >*</span></h1>
                    <input className="flex flex-col w-full items-start gap-2.5 p-4 self-stretch rounded-xl border-[#CCC] lg:mb-5 bg-[var(--W-Background,#FFF)] border border-gray-600" type='text' placeholder='ex. 998xxxxxxx' value={Number} onChange={handleNumber}></input>
                    {NumberError && <div className='flex m-2'><Icon></Icon> <p className="text-red-500 text-md ">{NumberError}</p></div>}
                </div>
                <div className='w-full'>
                    <h1 className='text-[#666666] text-xs font-medium leading-[18px] font-helvetica md:text-[18px] mt-3 mb-3'>Industry<span className='text-red-500 text-sm pl-[2px]' >*</span></h1>
                    <input className="flex flex-col w-full items-start gap-2.5 p-4 self-stretch rounded-xl border-[#CCC] lg:mb-5 bg-[var(--W-Background,#FFF)] border border-gray-600  " type='text' placeholder='ex. Tech' value={Industry} onChange={(e) => setIndustry(e.target.value)}></input>
                    {IncompleteInfo && <div className='flex m-1'><Icon></Icon> <p className="text-red-500 text-md "> Please complete your details first</p></div>}
                </div>
            </div>
            <div className='flex items-center gap-2 self-stretch mt-5'>
                <button className='flex justify-center items-center gap-[7.864px] px-[36px] pt-[10px] pb-[8px] rounded-[6.291px] border-[0.786px] border-black opacity-80 font-semibold w-auto' onClick={()=>handlerest()}>Reset</button>
                <button className='flex justify-center items-center gap-[7.864px] px-[36px] pt-[10px] pb-[8px] rounded-[6.291px] bg-[#2F3E46] font-semibold text-white ' onClick={() => handleRegister()} disabled={DisablePay} >{!DisablePay?"Register Now":"Please Wait"}</button>
            </div>
        </div>
    );
}

export default Forms;












// const handlePay = async (price, itemName) => {
    //     setcandidate_id((id=>id+1));
    //     if(Name.length===0 || Email.length===0 || Industry.length===0 || Number.length===0){
    //         setIncompleteInfo(true);
    //         return ;
    //     }
    //     else {
    //         setIncompleteInfo(false);
    //     }
    //     setDisablePay(true);
    //     try {
    //         const options = {
    //             session_id: 1,
    //             candidate_id: candidate_id,
    //             amount: parseInt(price),
    //         };
    //         const res = await axios.post(http://localhost:3000/api/payments, options);
    //         const data = res.data;
    //         // console.log(data)
    //         const paymentObject = new window.Razorpay({
                

    //             key: process.env.key_id,
    //             amount: data.amount,
    //             currency: data.currency,
    //             name: 'Happy Aspirants',
    //             description: itemName,
    //             order_id: data.id,
                
    //             handler: async function (response) {
    //                  console.log(response);
    //                 const verifyOptions = {
    //                     order_Id: response.razorpay_order_id,
    //                     payment_Id: response.razorpay_payment_id,
    //                     signature: response.razorpay_signature,
    //                 };
    //                 //https://happyaspirantstest-2.onrender.com
    //                 const verifyRes = await axios.post('http://localhost:3000/api/verifyOrder', verifyOptions);

    //                 if (verifyRes.data.success) {
    //                     alert('Payment Verified Successfully');
    //                     try{
    //                         const userEmail={
    //                             Email:Email,
    //                         }
    //                         const maildata=await axios.post("http://localhost:3000/api/SendEmail",userEmail);
    //                     }
    //                     catch(err){
    //                         console.log("err:",err);
    //                         alert('Our team will be contacting you soon');
    //                     }
    //                 } else {
    //                     alert('Payment Verification Failed');
    //                 }
    //             },
    //             prefill: {
    //                 name: Name,
    //                 email: Email,
    //                 contact: Number,
    //             },
    //             notes: {
    //                 industry: Industry,
    //             },
    //             theme: {
    //                 color: '#F37254',
    //             },
    //             onClose: function () {
    //                 alert('Payment was cancelled by the user.');
    //             }
    //         });
            
    //         paymentObject.open();
    //     } catch (error) {
    //         console.error('Payment Failed:', error);
    //         alert('Payment Failed: ' + (error instanceof Error ? error.message : String(error)));
    //     }
    //     setDisablePay(false);
    // };