import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./Components/Homepage";
import './index.css';
import Landingpage from './Components/Landingpage';

function App() {
  return (
    <Router>
      <div className="-mt-3 min-h-screen">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/registerSession" element={<Homepage/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;