import React from 'react'
import { ReactComponent as MySvg } from '../Photos/target-and-arrow-svgrepo-com 1 (1).svg'
import { ReactComponent as MySvg1 } from '../Photos/energy-svgrepo-com 1 (1).svg'
import { ReactComponent as MySvg2 } from '../Photos/skills-svgrepo-com 1.svg'
import { ReactComponent as MySvg3 } from '../Photos/suitcase-1-svgrepo-com 1 (1).svg'
import SvgCarousel from './SvgCarousel'

const BodyHeadings = () => {
  // Define the session date and time
  const sessionDateTime = '15 September 2024, 02:00 PM';

  return (
    <div className='flex flex-wrap justify-start items-start h-full md:ml-1'>
        <div className='width-[390px]'>
        </div>
        <h1 className='text-[#2F3E46] text-[28px] md:text-[40px] font-helvetica font-semibold mt-3'>
        Interview & Life Prep Beyond Basics - Session 
        </h1>
        <h1 className='text-[#2F3E46] text-[14px] md:text-[21px] font-helvetica italic mt-3 mb-3 xsm:w-[35rem]'>
        Enhance your interview readiness and personal growth in a single expert-led session. Covering key areas like technical prep, mindset, and soft skills, this session is designed to help you excel in your career and beyond.
        </h1>
        {/* Add session date and time */}
        {/* <h2 className='text-[#2F3E46] text-[14px] md:text-[18px] font-helvetica font-bold mt-2'>
          Session Date & Time: {sessionDateTime}
        </h2> */}
        <div className='flex flex-wrap justify-start gap-2 mt-4 items-start content-start md:gap-3 md:w-[528px]'>
           <h1 className='flex bg-[#FFEAC3] rounded-md items-center p-y-1 p-x-2 gap-1'><MySvg /><p className='text-black text-lg font-medium leading-normal font-helvetica pr-2'>Journey To Microsoft</p></h1> 
           <h1 className='flex bg-[#FFEAC3] rounded-md items-center p-y-1 p-x-2 gap-1'><MySvg1 /><p className='text-black text-lg font-medium leading-normal font-helvetica pr-2'>Common Mistakes to avoid in Interview</p></h1> 
           <h1 className='flex bg-[#FFEAC3] rounded-md items-center p-y-1 p-x-2 gap-1'><MySvg2 /><p className='text-black text-lg font-medium leading-normal font-helvetica pr-2'>Motivation</p></h1> 
           <h1 className='flex bg-[#FFEAC3] rounded-md items-center p-y-1 p-x-2 gap-1'><MySvg3 /><p className='text-black text-lg font-medium leading-normal font-helvetica pr-2'>Soft Skills</p></h1> 
        </div>
        {/* Adjusted the div for "What people say -" to ensure proper alignment */}
        <div className='mt-6 md:w-full '> 
            <h1 className='ml-0 font-helvetica text-[14px] md:text-[21px] italic md:font-medium md:leading-normal'>
                What people say -
            </h1>
        </div>
        <div className='flex xmd:-ml-[10.25rem] mx-auto justify-start md:-ml-5 sm:-ml-5 w-full h-full items-start md:w-[502.5px] md:ml-0 md:py-3 md:px-[18px] md:gap-[36px]'>
            <SvgCarousel />
        </div>
    </div>
  )
}

export default BodyHeadings