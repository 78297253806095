import React, { useEffect, useState } from 'react';
import image1 from '../Photos/Testimonial - 1.svg';
import image2 from '../Photos/Testimonial - 2.svg';
import image3 from '../Photos/Testimonial - 3.svg';
import image4 from '../Photos/Testimonial - 4.svg';

const data = [image2, image1, image3, image4];

const SvgCarousel = () => {
  const [index, setIndex] = useState(0);
  
  useEffect(()=>{
    let x= setInterval(()=>{
      setIndex((prevIndex) => (prevIndex+1)%data.length);
    },5000)
    return ()=>{clearInterval(x)};
  },[])

  return (
    <div className='relative mt-3 mb-3 w-full max-w-md mx-auto '>
      <img src={data[index]} alt="comments" className='w-full' />
    </div>
  );
};

export default SvgCarousel;