import React from 'react'
import { ReactComponent as Logo } from '../Photos/logo.svg'
const Navbar = () => {
  return (
    <div class="h-[5rem] w-full border-b-4 ">
        <div className='flex justify-center items-center p-4 m-3 cursor-pointer pb-2'>
          <a href='/'>
        <Logo></Logo>
        </a>
        </div>
    </div>
  )
}

export default Navbar