import React from 'react';
import Navbar from './Navbar';
import Body from './Body';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Homepage = () => {
  const query = useQuery();
  const id = query.get('id');

  return (
    <div>
      {(id === 'interviewPrepBeyondBasics' || id==null) ? (
        <div className='bg-[#EFF3F8] min-h-screen flex flex-col'>
          <Navbar />
          <div className='flex-grow flex items-center justify-center'>
            <Body />
          </div>
        </div>
      ) : (
        <h1 className='mt-3'>Switch to the right session</h1>
      )}
    </div>
  );
}

export default Homepage;