import React from 'react'

const Footer = () => {
  return (
    <div className=' md:relative flex flex-col lg:mt-[7rem] h-full w-full pt-8 pr-0 pb-8 pl-[11px] justify-center items-center gap-[32px] bg-[#2F3E46] text-[#FFFFFF] '>
      <div class="flex flex-col lg:items-center gap-5 md:flex-row py-0 px-4 self-stretch  ">
       <a href='https://www.instagram.com/happy.aspirants?igsh=Y3VpZjE2ZHZ2a2Zn&utm_source=qr'> <h1 className='text-white font-montserrat text-base font-medium leading-normal capitalize self-stretch cursor-pointer'>Instagram</h1></a>
        <a href='https://www.linkedin.com/in/komal-kungwani-221282152/'><h1 className='text-white font-montserrat text-base font-medium leading-normal capitalize self-stretch -mt-5 md:mt-0 cursor-pointer'>Linkedin</h1></a>
        <h1 className='text-white font-montserrat text-base  font-medium leading-normal  self-stretch -mt-5 md:mt-0 cursor-pointer'>support@happyaspirants.com</h1>
        <h1 className='text-white font-montserrat text-base  font-medium leading-normal capitalize self-stretch cursor-pointer items-end justify-end md:absolute  md:right-8'>Copyright © 2024 . All rights reserved</h1>
      </div>
    </div>
  )
}

export default Footer