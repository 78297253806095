import React from 'react';
import BodyHeadings from './BodyHeadings';
import Forms from './Forms';
import Footer from './Footer';

const Body = () => {
  return (
    <div className='h-full'>
      <div className='grid grid-cols-1 lg:mt-[4.5rem] md:grid-cols-2 lg:gap-[15rem] md:gap-[8rem] md:ml-24 md:mr-10 md:mt-8 md:mb-10 m-3 p-3 md:justify-center md:items-center md:align-content-center xsm:justify-center xsm:items-center md:flex-wrap'>
        <div className='flex justify-center ml-2 xsm:justify-center'>
          <BodyHeadings />
        </div>
        <div className='flex justify-start w-full xsm:justify-center xsm:items-center'>
          <Forms />
        </div>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  );
};

export default Body;